import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Container, CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import Appbar from './components/Appbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import EventPage from './pages/EventPage';
import IframePage from './pages/IframePage';
import NotFound from './pages/NotFound';
import ChangeNamePage from './pages/ChangeNamePage';
import RefundPage from './pages/RefundPage';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ff0c0c', // Colore principale
    },
    secondary: {
      main: '#f50057', // Colore secondario
    },
    // Puoi definire ulteriori colori qui
  },
});

function LoadingScreen() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>
  );
}
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <style>{`
          body {
            margin: 0;
          }
        `}</style>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
        <Appbar />
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/evento/:id" element={<EventPage />} />
            <Route path="/evento/:id/prenota" element={<IframePage/>} />
            <Route path="/cambiautilizzatore" element={<ChangeNamePage/>} />
            <Route path="/cambia-utilizzatore" element={<ChangeNamePage/>} />
            <Route path="/restituiscititolo" element={<RefundPage/>} />
            <Route path="/restituisci-titolo" element={<RefundPage/>} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
          </>
        )}
      </Router>
      <Footer />
    </ThemeProvider>
  );
}

export default App;