import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function ChangeNamePage() {
  return (
    <Box mt={2} mb={2}>
      <Container fixed maxWidth="xl">
        <Typography variant="h4" gutterBottom>
          Cambia Nominativo sul Biglietto
        </Typography>
        <Typography variant="body1">
          Per cambiare il nominativo sul biglietto, utilizza il sistema informatico proprietario delle biglietterie interne. Segui le istruzioni fornite dal sistema per effettuare la modifica desiderata.
        </Typography>
        <Typography variant="body1">
          Si prega di notare che la disponibilità di questa funzionalità potrebbe essere limitata o non disponibile sul sistema delle biglietterie esterne. Si consiglia di consultare il personale delle biglietterie esterne per ulteriori informazioni o mandarci una mail a info@asuddelracconto.it.
        </Typography>
      </Container>
    </Box>
  );
}

export default ChangeNamePage;