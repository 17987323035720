import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Grid, Box, Button } from '@mui/material';

const NotFound = () => {
  return (
    <Box mt={2} mb={2}>
        <Container fixed maxWidth="xl">
        <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
                <Typography variant="h4">Oops! Pagina non trovata.</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">
                La pagina che stai cercando non esiste.
                </Typography>
            </Grid>
            <Grid item>
                <Button component={Link} to="/" variant="contained" color="primary">
                Torna alla Home
                </Button>
            </Grid>
        </Grid>
    </Container>
    </Box>
  );
};

export default NotFound;
