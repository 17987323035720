import React, { useEffect, useState, useRef } from 'react';
import { Typography, Container, Grid, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container fixed maxWidth="xl">
        <Grid container justify="center">
          <Grid item>
            <Typography variant="body2">
              © {new Date().getFullYear()} A Sud del Racconto - Tutti i diritti sono riservati
            </Typography>
            <Typography variant="body2">
              Sistema sviluppato da <Link underline="none" color="inherit" href="https://ottomanelliandrea.it">Andrea Ottomanelli</Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;