import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Typography, Button, Card, CardContent, Box, LinearProgress, Alert } from '@mui/material';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
//import LinearProgress from '@mui/joy/LinearProgress';

function EventPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState(null);
  const [isBookingOpen, setIsBookingOpen] = useState(false);
  const [customHTML, setCustomHTML] = useState(null);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const customIcon = L.icon({
    iconUrl: 'https://cdn.jsdelivr.net/npm/leaflet@1.9.3/dist/images/marker-icon-2x.png',
    iconSize: [25, 41],
  });

  useEffect(() => {
    fetchEventData();
  }, []);

  const fetchEventData = async () => {
    try {
      const response = await axios.get(`https://asuddelracconto.ottomanelliandrea.it/bigl/api/events-wp.php?id=${id}`);
      const data = response.data;
      setEventData(data);
      checkBookingStatus(data);
    } catch (error) {
      console.log('Error fetching event data:', error);
    }
  };

  const checkBookingStatus = (data) => {
    /*const currentDateTime = new Date();
    const eventDateTime = new Date(data.date);
    const openingDateTime = new Date(data.openingDate);
    const closingDateTime = new Date(data.closingDate);

    const isBookingOpen =
      currentDateTime >= openingDateTime &&
      currentDateTime <= closingDateTime &&
      currentDateTime >= eventDateTime;

    console.log(currentDateTime >= openingDateTime);*/

    setIsBookingOpen(data.vendita === true);

    if (isBookingOpen) {
      fetchCustomHTML(); // Fetch custom HTML if booking is open
    }
  };

  const fetchCustomHTML = async () => {
    try {
      const response = await axios.get('https://example.com/ajax/html');
      const html = response.data;
      setCustomHTML(html);
    } catch (error) {
      console.log('Error fetching custom HTML:', error);
    }
  };

  const handleBookingLoad = () => {
    // Adjust iframe height if needed
  };

  const handleButtonClick = () => {
    //setIsContentVisible(true);
    navigate(`/evento/${id}/prenota`);
  };

  if (!eventData) {
    return <Box mt={2} mb={2}><Container fixed maxWidth="xl"><LinearProgress /></Container></Box>
  }

  return (
    <Box mt={2} mb={2}>
        <Container fixed maxWidth="xl">
        <div gutterTop>
        <Typography variant="h4" gutterBottom fontWeight="bold">
            {eventData.title}
        </Typography>
        <Box sx={{ display: 'grid', gridGap: '1rem', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              Luogo evento:
            </Typography>
            <Typography variant="body1">{eventData.location.name}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              Data e ora:
            </Typography>
            <Typography variant="body1">
              {eventData.dateM} alle {eventData.timeM}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              Organizzatore:
            </Typography>
            <Typography variant="body1">{eventData.organizer}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              Numero massimo di biglietti acquistabili:
            </Typography>
            <Typography variant="body1">{eventData.maxTickets}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              Inizio vendite:
            </Typography>
            <Typography variant="body1">{eventData.openingDate}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              Fine vendite:
            </Typography>
            <Typography variant="body1">{eventData.closingDate}</Typography>
          </Box>
        </Box>
        <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{ __html: eventData.dettagli_mostrare }}></Typography>
        {/* Add other event details like location, map, etc. */}
        {eventData.location && (
            <Card>
                <MapContainer
                center={[eventData.location.latitude, eventData.location.longitude]}
                zoom={13}
                style={{ height: '400px' }}
                >
                <TileLayer url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@10x.png" />
                <Marker position={[eventData.location.latitude, eventData.location.longitude]} icon={customIcon}>
                    <Popup>{eventData.location.name}</Popup>
                </Marker>
                </MapContainer>
            </Card>
        )}
        {isBookingOpen ? (
            <Box mt={2}>
            {isContentVisible ? (
            <Button variant="contained" onClick={handleButtonClick} disabled>
                Prenota
                </Button>
            ) : (
                <Button variant="contained" onClick={handleButtonClick}>
                Prenota
                </Button>
            )}
            </Box>
        ) : (
          <Box mt={2}>
            <Alert severity="error">Le vendite non sono attualmente aperte.</Alert>
          </Box>
        )}
        </div>
        </Container>
    </Box>
  );
}

export default EventPage;