import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function RefundPage() {
  return (
    <Box mt={2} mb={2}>
      <Container fixed maxWidth="xl">
        <Typography variant="h4" gutterBottom>
          Richiesta Rimborso
        </Typography>
        <Typography variant="body1">
          Per richiedere un rimborso con una penale di 2€, utilizza il sistema informatico esterno delle biglietterie esterne, se disponibile. Segui le istruzioni fornite dal sistema per avviare la procedura di rimborso e gestire il pagamento della penale.
        </Typography>
        <Typography variant="body1">
          Si prega di notare che la disponibilità di questa funzionalità potrebbe variare tra le biglietterie esterne. Si consiglia di consultare il personale delle biglietterie esterne per ulteriori informazioni sulla procedura di rimborso.
        </Typography>
      </Container>
    </Box>
  );
}

export default RefundPage;