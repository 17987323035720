import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Typography, List, ListItem, ListItemText, Box, LinearProgress, Tab, Tabs, Alert } from '@mui/material';

function HomePage() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    const hash = location.hash.substring(1);
    setActiveTab(parseInt(hash) || 0);
  }, [location]);

  const fetchEvents = async () => {
    try {
      const response = await fetch('https://asuddelracconto.ottomanelliandrea.it/bigl/api/events-wp.php');
      const data = await response.json();
      setEvents(data);
      setIsLoading(false);
    } catch (error) {
      console.log('Error fetching events:', error);
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (isLoading) {
    return (
      <Box mt={2} mb={2}>
        <div>
          <Container fixed maxWidth="xl">
            <Typography variant="h5" gutterBottom>
              <strong>I prossimi eventi</strong>
            </Typography>
            <Box mt={2}><LinearProgress /></Box>
          </Container>
        </div>
      </Box>
    );
  }

  const filteredEvents = activeTab === 0 ? events.filter(event => !event.scuole && !event.abbonamento && !event.fidelityCard) : activeTab === 1 ? events.filter(event => event.scuole) : activeTab === 2 ? events.filter(event => event.abbonamento) : events.filter(event => event.fidelityCard);


  return (
    <Box mt={2} mb={2}>
      <div>
        <Container fixed maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            <strong>I prossimi eventi</strong>
          </Typography>
          <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            <Tab label="Spettacoli" component={Link} to="#0" />
            <Tab label="Spettacoli per scuole" component={Link} to="#1" />
            <Tab label="Abbonamenti" component={Link} to="#2" />
            <Tab label="Fidelity Card" component={Link} to="#3" />
          </Tabs>
          {filteredEvents.length === 0 ? (
            <Box mt={2} mb={2}>
              <Alert severity="error">Non ci sono eventi trovati.</Alert>
            </Box>
          ) : (
            <List>
              {filteredEvents.map((event) => (
                <ListItem key={event.id} button component={Link} to={`/evento/${event.id}`}>
                  <ListItemText primary={<strong>{event.title}</strong>} secondary={event.date} />
                </ListItem>
              ))}
            </List>
          )}
        </Container>
      </div>
    </Box>
  );
}

export default HomePage;