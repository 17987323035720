import React from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton } from '@mui/material';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

function Appbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Biglietteria
        </Typography>
        <Button component={Link} to="/" color="inherit">Eventi</Button>
        <IconButton color="inherit" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
        >
          <MenuItem onClick={handleMenuClose} component={Link} to="/cambiautilizzatore">Cambia utilizzatore</MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/restituiscititolo">Restituisci titolo</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Appbar;