import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Container, Typography, Button, Card, CardContent, Alert, Box } from '@mui/material';
import IframeResizer from 'iframe-resizer-react'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    iframe: {
      width: 1,
      border: 'none',
    },
  });

function IframePage() {
    const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [iframeUrl, setIframeUrl] = useState('');
  const [customHtml, setCustomHtml] = useState('');
  const [isBookingOpen, setIsBookingOpen] = useState(false);
  const classes = useStyles();
  const iframeRef = useRef(null);

  useEffect(() => {
    const fetchBookingStatus = async () => {
      try {
        const response = await fetch(`https://asuddelracconto.ottomanelliandrea.it/bigl/api/events-wp.php?id=${id}`);
        const data = await response.json();
        const currentDateTime = new Date();
        const eventDateTime = new Date(data.date);
        const openingDateTime = new Date(data.openingDate);
        const closingDateTime = new Date(data.closingDate);

        setIsBookingOpen(data.vendita === true && (data.bookingType === 'iframe' || data.bookingType === 'custom'));
        
        if (data.bookingType === 'iframe') {
          setIframeUrl(data.bookingURL);
        } else if (data.bookingType === 'custom') {
          const response = await fetch('https://example.com/ajax/html');
          const html = await response.text();
          setCustomHtml(html);
        }
      } catch (error) {
        console.log('Error fetching booking status:', error);
      }
    };

    fetchBookingStatus();
  }, [location.search]);


  const handleIframeLoad = () => {
    const wrapper = document.getElementById('ifw');;
   window.parentIFrame.size(wrapper.offsetHeight, wrapper.offsetWidth);
};

  return (
    <div>
      {isBookingOpen && (
        <>
          {iframeUrl && (
            <div id="ifw">
            <IframeResizer
            className={classes.iframe}
            forwardRef={iframeRef}
            heightCalculationMethod="lowestElement"
            inPageLinks
            checkOrigin="false"
            autoResize={false}
            src={iframeUrl}
            frameBorder="0"
            style={{ width: '1px', minWidth: '100%'}}
          />
          </div>
          )}
          {customHtml && (
            <div dangerouslySetInnerHTML={{ __html: customHtml }} />
          )}
        </>
      )}
      {!isBookingOpen && (
        <Box mt={2} mb={2}>
          <Container>
            <Alert severity="error">Le vendite non sono attualmente aperte.</Alert>
          </Container>
        </Box>
      )}
    </div>
  );
}

export default IframePage;
